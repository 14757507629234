import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Navigation = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <nav className="nav">
      <ul className="nav__list">
        {/* Добавить авто */}
        <li className="nav__item">
          <Link to="/applications/create" className="nav__link">
            Добавить авто
          </Link>
        </li>

        {/*  */}

        {/* Авто */}
        <li
          className={`nav__item ${
            user.role !== "Operator" && user.role !== "Manager" && user.role !== "Partner"
              ? "nav__item-dd"
              : ""
          }`}
        >
          {user.role !== "Partner" ?
          <Link to="/applications" className="nav__link">
            Авто
          </Link>
           : ''}
          {user.role !== "Operator" && user.role !== "Manager" ? (
            <ul className="nav__item-dd-list">
              <li className="">
                <Link to="/applications/dublicate">Дубли</Link>
              </li>
              <li>
                <Link to="/applications/8">Удаленные</Link>
              </li>
            </ul>
          ) : (
            ""
          )}
        </li>
        {/*  */}

        {user.role !== "Operator" ? (
          <>
            {/* Таблицы */}
            {user.role !== "Manager" && (
              <li className="nav__item nav__item-dd">
                <a href="" className="nav__link">
                  Таблицы
                </a>
                <ul className="nav__item-dd-list">
                  {user.role !== "Partner" && (
                  <li>
                    <Link to="/legals" className="nav__link">
                      Юр.лица
                    </Link>
                  </li>
                  )}
                  <li>
                    <Link to="/parkings" className="nav__link">
                      Стоянки
                    </Link>
                  </li>
                  {user.role !== "Partner" ?
                      <li>
                        <Link to="/partners" className="nav__link">
                          Заказчики
                        </Link>
                      </li> : ''
                  }

                  <li>
                    <Link to="/users" className="nav__link">
                      Пользователи
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {/*  */}

            {/* Выгрузки */}
            <li className="nav__item nav__item-dd">
              <a href="" className="nav__link">
                Выгрузки
              </a>
              <ul className="nav__item-dd-list">
                <li>
                  <Link to="/report-by-partner" className="nav__link">
                    {user.role !== "Partner" ? 'Отчёт по Заказчикам' : 'Отчёт по Хранению' }
                  </Link>
                </li>
                {user.role !== "Partner" && (
                <li>
                  <Link to="/report-by-employee" className="nav__link">
                    Отчёт по сотрудникам
                  </Link>
                </li>)}
                {user.role !== "Partner" && (
                <li>
                  <Link to="/report-all-partner" className="nav__link">
                    {user.role !== "Partner" ?  'Отчёт по дням ' : 'Отчёт по всем Заказчикам' }
                  </Link>
                </li>
                )}
                <li>
                  <Link to="/report-order" className="nav__link">
                    Отчёт по услугам
                  </Link>
                </li>
              </ul>
            </li>
            {/*  */}

            {/* Настройки */}
            {user.role === "SuperAdmin" && (
              <li className="nav__item nav__item-dd">
                <a href="" className="nav__link">
                  Настройки
                </a>
                <ul className="nav__item-dd-list">
                  <li>
                    <a href="">Тип заказчика</a>
                  </li>
                  <li>
                    <Link to="/permissions"> Разрешения </Link>
                  </li>
                  {/*<li>*/}
                  {/*  <a href="">Элементы html</a>*/}
                  {/*</li>*/}
                  <li>
                    <Link to="/logs"> Логи </Link>
                  </li>
                  {user.role === "SuperAdmin" ?
                  <li>
                    <Link to="/guide/coverage"> Справочник </Link>
                  </li>
                      : '' }
                </ul>
              </li>
            )}
            {/*  */}
          </>
        ) : (
          ""
        )}
      </ul>
    </nav>
  );
};

export default Navigation;
