import styles from '../permission.module.css'
function PermissionItem({permission}) {
    return (
        <tr>
            <td className={styles['permission-actions']}>
                <b>{permission.titleRu}</b>
                <span>{permission.titleEn}</span>
            </td>
           <td>
               <label htmlFor="">
                   <input type="checkbox"/>
               </label>
           </td>
            <td>
                <label htmlFor="">
                    <input type="checkbox"/>
                </label>
            </td>
            <td>
                <label htmlFor="">
                    <input type="checkbox"/>
                </label>
            </td>
            <td>
                <label htmlFor="">
                    <input type="checkbox"/>
                </label>
            </td>
            <td>
                <label htmlFor="">
                    <input type="checkbox"/>
                </label>
            </td>
            <td>
                <label htmlFor="">
                    <input type="checkbox"/>
                </label>
            </td>
            <td>
                <label htmlFor="">
                    <input type="checkbox"/>
                </label>
            </td>
            <td>
                <label htmlFor="">
                    <input type="checkbox"/>
                </label>
            </td>
            <td>
                <label htmlFor="">
                    <input type="checkbox"/>
                </label>
            </td>
            <td>
                <label htmlFor="">
                    <input type="checkbox"/>
                </label>
            </td>
        </tr>
    );
}
export default PermissionItem;