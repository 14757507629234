import React from 'react';
import PermissionsHeader from "./permissionsHeader/permissionsHeader";
import PermissionItem from "./permissionItem/permissionItem";

function PermissionsList(props) {
    const permissionsRoleList = [
        {role: 'SuperAdmin'},
        {role: 'Admin'},
        {role: 'Manager'},
        {role: 'Operator'},
        {role: 'Partner'},
        {role: 'PartnerOperator'},
        {role: 'Moderator'},
        {role: 'Contractor'},
        {role: 'ContractorManager'},
        {role: 'ContractorOperator'}
    ]
    const permissionsWithTranslations = [
        { titleEn: "user_view", titleRu: "Просмотр пользователей" },
        { titleEn: "user_create", titleRu: "Создание пользователей" },
        { titleEn: "user_update", titleRu: "Редактирование пользователей" },
        { titleEn: "user_delete", titleRu: "Удаление пользователей" },
        { titleEn: "permission_update", titleRu: "Редактирование прав" },
        { titleEn: "legal_view", titleRu: "Просмотр юридических документов" },
        { titleEn: "legal_create", titleRu: "Создание юридических документов" },
        { titleEn: "legal_update", titleRu: "Редактирование юридических документов" },
        { titleEn: "legal_delete", titleRu: "Удаление юридических документов" },
        { titleEn: "parking_view", titleRu: "Просмотр парковки" },
        { titleEn: "parking_create", titleRu: "Создание парковки" },
        { titleEn: "parking_update", titleRu: "Редактирование парковки" },
        { titleEn: "parking_delete", titleRu: "Удаление парковки" },
        { titleEn: "partner_view", titleRu: "Просмотр партнёров" },
        { titleEn: "partner_create", titleRu: "Создание партнёров" },
        { titleEn: "partner_update", titleRu: "Редактирование партнёров" },
        { titleEn: "partner_type_view", titleRu: "Просмотр типов партнёров" },
        { titleEn: "partner_type_create", titleRu: "Создание типов партнёров" },
        { titleEn: "partner_type_update", titleRu: "Редактирование типов партнёров" },
        { titleEn: "application_view", titleRu: "Просмотр заявок" },
        { titleEn: "application_create", titleRu: "Создание заявок" },
        { titleEn: "application_update", titleRu: "Редактирование заявок" },
        { titleEn: "application_delete", titleRu: "Удаление заявок" },
        { titleEn: "application_to_accepted", titleRu: "Принятие заявки" },
        { titleEn: "application_to_issue", titleRu: "Выдача заявки" },
        { titleEn: "application_to_inspection", titleRu: "Инспекция заявки" },
        { titleEn: "application_issue", titleRu: "Выпуск заявки" },
        { titleEn: "notify_app_pending", titleRu: "Уведомление о заявке в ожидании" },
        { titleEn: "notify_app_storage", titleRu: "Уведомление о заявке на хранении" },
        { titleEn: "notify_app_issuance", titleRu: "Уведомление о выдаче заявки" },
        { titleEn: "notify_app_issued", titleRu: "Уведомление о выпущенной заявке" },
        { titleEn: "notify_app_denied", titleRu: "Уведомление об отклонённой заявке" },
        { titleEn: "notify_app_deleted", titleRu: "Уведомление об удалённой заявке" },
        { titleEn: "notify_app_viewRequest1", titleRu: "Просмотр запроса 1" },
        { titleEn: "notify_app_viewRequest2", titleRu: "Просмотр запроса 2" },
        { titleEn: "notify_app_viewRequest3", titleRu: "Просмотр запроса 3" },
        { titleEn: "notify_app_moderation", titleRu: "Модерация заявки" },
        { titleEn: "notify_app_chat_message", titleRu: "Сообщение в чате заявки" }
    ];

    return (
        <>
            <PermissionsHeader/>
            <div className="container">
                <table>
                    <thead>
                        <tr>
                            <th key={'doing'}>Действие</th>
                            {permissionsRoleList.map(prRole => (<th key={prRole.role}>{prRole.role}</th> ))}
                        </tr>
                    </thead>
                    <tbody>
                        {permissionsWithTranslations.map(permission => (
                           <PermissionItem key={permission.titleEn} permission={permission} />
                        ))}

                    </tbody>
                </table>
            </div>
        </>
    );
}
export default PermissionsList;