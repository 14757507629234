import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import ApplicatonCrtUptHeader from "./applications-crt-upt-header/applications-crt-upt-header";
import ApplicationEditStep1 from "./applications-edit-step-1/applications-edit-step-1";
import ApplicationEditStep2 from "./applications-edit-step-2/applications-edit-step-2";
import Preloader from "../../preloader/preloader";

import { Nav, Tab } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  applicationsEditGet,
  editApplicatioionReset,
} from "../../../store/applications/applications-edit/applications-edit-get/applicationsEditSlice";
import { applicationsEditPut } from "../../../store/applications/applications-edit/applications-edit-put/applicaitonsEditPutSlice";
import swal from "sweetalert";
import { selectColor } from "../../../utils/select-array";
import { applicationsAll } from "../../../store/applications/applicationsSlice";
import { serialNumberReset } from "../../../store/applications/application-vin-dublicate/applicationVinDublicateSlice";
import {applicationCarTypes} from "../../../store/applications/applications-create/application-create-get/applicationCreateSlice";

function ApplicationEdit() {
  const { user } = useSelector((state) => state.auth);
  const [disabledEditBtn, setDisabledEditBtn] = useState(false);
  const [automaticSelect, setAutomaticSelect] = useState(false);
  const carTypesList = useSelector((state) => state.applicationCreate.carTypes.carTypes);

  localStorage.removeItem("attachment_token");
  const [dataUpdateLoaded, setUpdateLoaded] = useState(false);

  const { applicationsEdit } = useSelector(
    (state) => state.applicationsEditData
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  useEffect(() => {
    dispatch(editApplicatioionReset());
    dispatch(serialNumberReset());
    dispatch(applicationsEditGet(id)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setUpdateLoaded(true);
      }
    });
  }, [id, navigate]);

  const [currentTab, setCurrentTab] = React.useState(0);

  const styles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      color: "black",
      backgroundColor: state.data.color,
      fontSize: state.selectProps.myFontSize,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "black",
      width: "160px",
      fontSize: state.selectProps.myFontSize,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  const tabFucnt = (tab) => {
    setCurrentTab(tab);
    getCarTypes()
  };
  const { isError, isSuccess, message } = useSelector(
    (state) => state.applicationResultPost
  );

  useEffect(() => {
    if (isError) {
      toast.error(message.error);
    }

    if (isSuccess) {
      navigate("/applications");
    }
  }, [isError, isSuccess, message, navigate, dispatch]);

  const onUpdateApplicaiton = (e) => {
    e.preventDefault();
    setDisabledEditBtn(true);
    setAutomaticSelect(false);
    dispatch(
      applicationsEditPut({
        id: applicationsEdit.application.id,
        data: updateAppData,
        role: user.role,
      })
    ).then((response) => {
      if (response.payload.status === 200) {
        swal("Обновлен!", {
          icon: "success",
        }).then(() => {
          setDisabledEditBtn(false);
          // dispatch(applicationsAll());
          // dispatch(applicationsEditGet(id));
          window.location.replace("/applications");
        });
      } else {

        let errorMessage = "Ошибка при обновлении!";
        setDisabledEditBtn(false);

        if (response.error.message === 'Rejected') {
           errorMessage = response.payload.message
        }
        swal(errorMessage, {
          icon: "error",
        });
      }
    });
  };

  // Собираем данные в один useState чтобы потом отправить его для обновление
  const [updateAppData, setUpdateAppData] = useState({});
  useEffect(() => {
    let defaultStatusToken = { status_id: 2 };
    if (user.role === "Operator") {
      defaultStatusToken = { status_id: 7 };
    } else if (user.role === "Manager") {
      if (applicationsEdit?.application?.status_id === 3) {
        defaultStatusToken = { status_id: 3 };
      } else {
        defaultStatusToken = { status_id: 2 };
      }
    }
    if (user.role === "Operator") {
      setUpdateAppData({
        arriving_at: applicationsEdit.application?.arriving_at
          ? applicationsEdit.application?.arriving_at
          : null,
        arriving_interval: applicationsEdit.application?.arriving_interval
          ? applicationsEdit.application?.arriving_interval
          : null,
        courier_fullname: applicationsEdit.application?.courier_fullname
          ? applicationsEdit.application?.courier_fullname
          : null,
        courier_phone: applicationsEdit.application?.courier_phone
          ? applicationsEdit.application?.courier_phone
          : null,
        parking_id: applicationsEdit.application?.parking
          ? applicationsEdit.application?.parking?.id
          : null,
        partner_id: applicationsEdit.application?.partner
          ? applicationsEdit.application?.partner?.id
          : null,
        status_id: defaultStatusToken.status_id,
        external_id: applicationsEdit.application?.external_id
          ? applicationsEdit.application?.external_id
          : null,
        vin_array: applicationsEdit.application?.vin
          ? applicationsEdit.application?.vin
          : null,
        license_plate: applicationsEdit.application?.license_plate
          ? applicationsEdit.application?.license_plate
          : null,
        sts: applicationsEdit.application?.sts
          ? applicationsEdit.application?.sts
          : null,
        pts: applicationsEdit.application?.pts
          ? applicationsEdit.application?.pts
          : null,
        car_key_quantity: applicationsEdit.application?.car_key_quantity
          ? applicationsEdit.application?.car_key_quantity
          : 0,
        milage: null,
        car_additional: applicationsEdit.application?.car_additional
          ? applicationsEdit.application?.car_additional
          : null,
        car_type_id: applicationsEdit.application?.car_type_id
          ? applicationsEdit.application?.car_type_id
          : null,
        car_mark_id: applicationsEdit.application?.car_mark_id
          ? applicationsEdit.application?.car_mark_id
          : null,
        car_model_id: applicationsEdit.application?.car_model_id
          ? applicationsEdit.application?.car_model_id
          : null,
        car_generation_id: applicationsEdit.application?.car_generation_id
          ? applicationsEdit.application?.car_generation_id
          : null,
        car_series_id: applicationsEdit.application?.car_series_id
          ? applicationsEdit.application?.car_series_id
          : null,
        car_modification_id: applicationsEdit.application?.car_modification_id
          ? applicationsEdit.application?.car_modification_id
          : null,
        car_engine_id: applicationsEdit.application?.car_engine_id
          ? applicationsEdit.application?.car_engine_id
          : null,
        car_transmission_id: applicationsEdit.application?.car_transmission_id
          ? applicationsEdit.application?.car_transmission_id
          : null,
        car_gear_id: applicationsEdit.application?.car_gear_id
          ? applicationsEdit.application?.car_gear_id
          : null,
        year: applicationsEdit.application?.year
          ? applicationsEdit.application?.year
          : null,
        pts_provided: applicationsEdit.application?.pts_provided
          ? applicationsEdit.application?.pts_provided
          : null,
        sts_provided: applicationsEdit.application?.sts_provided
          ? applicationsEdit.application?.sts_provided
          : null,
      });
    } else {
      setUpdateAppData({
        arriving_at: applicationsEdit.application?.formated_arrived_at
          ? applicationsEdit.application?.formated_arrived_at
          : null,
        courier_fullname: applicationsEdit.application?.courier_fullname
          ? applicationsEdit.application?.courier_fullname
          : null,
        courier_phone: applicationsEdit.application?.courier_phone
          ? applicationsEdit.application?.courier_phone
          : null,
        parking_id: applicationsEdit.application?.parking
          ? applicationsEdit.application?.parking?.id
          : null,
        partner_id: applicationsEdit.application?.partner
          ? applicationsEdit.application?.partner?.id
          : null,
        status_id: defaultStatusToken.status_id,
        external_id: applicationsEdit.application?.external_id
          ? applicationsEdit.application?.external_id
          : null,
        vin_array: applicationsEdit.application?.vin
          ? applicationsEdit.application?.vin
          : null,
        license_plate: applicationsEdit.application?.license_plate
          ? applicationsEdit.application?.license_plate
          : null,
        sts: applicationsEdit.application?.sts
          ? applicationsEdit.application?.sts
          : null,
        pts: applicationsEdit.application?.pts
          ? applicationsEdit.application?.pts
          : null,
        car_key_quantity: applicationsEdit.application?.car_key_quantity
          ? applicationsEdit.application?.car_key_quantity
          : 0,
        milage: null,
        car_additional: applicationsEdit.application?.car_additional
          ? applicationsEdit.application?.car_additional
          : null,
        car_type_id: applicationsEdit.application?.car_type_id
          ? applicationsEdit.application?.car_type_id
          : null,
        car_mark_id: applicationsEdit.application?.car_mark_id
          ? applicationsEdit.application?.car_mark_id
          : null,
        car_model_id: applicationsEdit.application?.car_model_id
          ? applicationsEdit.application?.car_model_id
          : null,
        car_generation_id: applicationsEdit.application?.car_generation_id
          ? applicationsEdit.application?.car_generation_id
          : null,
        car_series_id: applicationsEdit.application?.car_series_id
          ? applicationsEdit.application?.car_series_id
          : null,
        car_modification_id: applicationsEdit.application?.car_modification_id
          ? applicationsEdit.application?.car_modification_id
          : null,
        car_engine_id: applicationsEdit.application?.car_engine_id
          ? applicationsEdit.application?.car_engine_id
          : null,
        car_transmission_id: applicationsEdit.application?.car_transmission_id
          ? applicationsEdit.application?.car_transmission_id
          : null,
        car_gear_id: applicationsEdit.application?.car_gear_id
          ? applicationsEdit.application?.car_gear_id
          : null,
        year: applicationsEdit.application?.year
          ? applicationsEdit.application?.year
          : null,
        pts_provided: applicationsEdit.application?.pts_provided
          ? applicationsEdit.application?.pts_provided
          : null,
        sts_provided: applicationsEdit.application?.sts_provided
          ? applicationsEdit.application?.sts_provided
          : null,
      });
    }
  }, [navigate, applicationsEdit]);
  //


useEffect(()=>{
  getCarTypes()
},[applicationsEdit])
  const getCarTypes = () => {
    const data = {
      parking_id : applicationsEdit.application?.parking?.id,
      partner_id : applicationsEdit.application?.partner?.id
    }
    dispatch(applicationCarTypes(data))
  }

  return (
    <>
      {dataUpdateLoaded ? (
        <form
          method="POST"
          onSubmit={onUpdateApplicaiton}
          encType="multipart/form-data"
        >
          <ApplicatonCrtUptHeader
            tab={currentTab}
            tabfunc={tabFucnt}
            title={applicationsEdit.title}
            status={applicationsEdit.statuses}
            statuscurrent={applicationsEdit?.application?.status_id}
            styles={styles}
            setUpdateAppData={setUpdateAppData}
            updateAppData={updateAppData}
            disabledEditBtn={disabledEditBtn}
          />
          <div className="container">
            <div className="inner-page">
              <div className="row no-gutters position-relative">
                <div className="tab-content tab-content-main col-md-12">
                  <Tab.Container id="left-tabs-example" activeKey={currentTab}>
                    <Nav variant="pills" className="d-none">
                      <Nav.Item>
                        <Nav.Link eventKey={0} disabled={currentTab !== 0}>
                          Tab 1
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey={1} disabled={currentTab !== 1}>
                          Tab 2
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey={0} disabled={currentTab !== 0}>
                        <ApplicationEditStep1
                          partners={applicationsEdit.partners}
                          parkings={applicationsEdit.parkings}
                          applications={applicationsEdit.application}
                          dateTime={applicationsEdit.dateTime}
                          setUpdateAppData={setUpdateAppData}
                          updateAppData={updateAppData}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey={1} disabled={currentTab !== 1}>
                        <ApplicationEditStep2
                          colors={selectColor(applicationsEdit.colors)}
                          applications={applicationsEdit.application}
                          tab={currentTab}
                          setUpdateAppData={setUpdateAppData}
                          updateAppData={updateAppData}
                          automaticSelect={automaticSelect}
                          setAutomaticSelect={setAutomaticSelect}
                          carTypesList={carTypesList}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <Preloader />
      )}
    </>
  );
}

export default ApplicationEdit;
