import React from 'react';

function ParkingEditPrices(
    {
        parkingPrice,
        parkingPartners,
        handlePriceChange,
        handleSelectChange,
        selectedOption
    }
) {

    return (
        <div className="row no-gutters">
            <div className="col-md-12">
                <div className="inner-page__item">
                    <div className="inner-item-title">Прайс-лист</div>

                    <div className="d-flex align-items-center">
                        <label
                            className="field-style mt-3"
                            // style="width: 100%; max-width: 605px;"
                        >
                            <span>Шаблон прайс-листов</span>
                            <select
                                name=""
                                id=""
                                className="page-select"
                                onChange={handleSelectChange}
                                // style="width: 100%; max-width: 605px;"
                            >
                                <option value={0}>Базовые цены</option>
                                {parkingPartners && parkingPartners.map((item, index)=>{
                                    return (
                                        <option
                                            key={item.id}
                                            value={item.id}
                                            selected={selectedOption == parseInt(item.id)  ? 'selected' : ''}
                                        >
                                            {item.name}
                                        </option>
                                    )
                                })}

                            </select>
                        </label>
                    </div>
                </div>
            </div>
            <div className="d-flex w-100">
                <table className="price-list-table price-table">
                    <thead>
                    <tr>
                        <th className="price-list-head">Категория</th>
                        <th className="text-center">Основная цена, ₽</th>
                        <th className="text-center">Специальная цена, ₽</th>
                        <th className="text-center">Свободных дней</th>
                    </tr>
                    </thead>
                    <tbody>
                    {parkingPrice && parkingPrice.map((item, index)=>{
                        return(
                            <tr >
                                <td>{item.car_type_name}</td>
                                <td>
                                    <div className="field-style">
                                        <input
                                            type="text"
                                            name={'regular_price'}
                                            className="center"
                                            value={item.regular_price}
                                            onChange={(e)=>{handlePriceChange(e,item.id)}}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="field-style">
                                        <input
                                            type="text"
                                            name={'discount_price'}
                                            className="center"
                                            value={item.discount_price}
                                            onChange={(e)=>{handlePriceChange(e,item.id)}}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="field-style">
                                        <input type="text"
                                               name={'free_days'}
                                               className="center"
                                               value={item.free_days}
                                               onChange={(e)=>{handlePriceChange(e,item.id)}}
                                        />
                                    </div>
                                </td>
                            </tr>
                        )
                    })
                    }
                    </tbody>
                </table>
            </div>
            {/*  */}
            {/*<div className=" d-flex justify-content-end mt-3 mb-3 pr-4">*/}
            {/*    <button type="submit"  className="btn btn-dark-blue">*/}
            {/*        Сохранить*/}
            {/*    </button>*/}
            {/*</div>*/}
        </div>

    );
}

export default ParkingEditPrices;